import { useState } from "react"
import { Menubar } from "./Components/Menubar"
import { Homepage } from "./Screens/Homepage"
import { Underconstruction } from "./Screens/Underconstruction"
import './css/main.css'


export const Layout = () => {

    const [view, setView] = useState('Underconstruction')
    const renderView = () => {
        switch (view){
            case 'Homepage':
                return <Homepage/>
            case 'Underconstruction':
                return <Underconstruction/>
            default:
                return <div></div>
        }

    }
    return (
        <div className="layout">
            {renderView()}
        </div>
    )
}