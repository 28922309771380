import mainlogo from '../assets/Logo.svg'
import '../css/main.css'

export const Underconstruction = () => {
    return (
        <div className='underConstruction'>
            <img className='uclogoColor' src={mainlogo}/>
            <h1 className='uc-H1'>we're under construction, but tasty things are coming!</h1>
        </div>
    )
}